import '../css/components/EmbossedCard.css';
import { useState } from 'react';

const EmbossedCard = ({ header, description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle the expanded state
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Conditionally display the description based on the state
  const displayedDescription = isExpanded
    ? description
    : `${description.substring(0, 600)}...`; // Show first 100 characters

  return (
    <div className="embossed-card">
      <h2 className="card-header">{header}</h2>
      <p className="card-description">
        {displayedDescription}
        {description.length > 600 && (
          <button className="read-more" onClick={toggleExpand}>
            {isExpanded ? 'Read less' : 'Read more'}
          </button>
        )}
      </p>
    </div>
  );
};

export default EmbossedCard;

