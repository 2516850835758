import React, { useRef, useEffect } from 'react';
import '../css/components/Banner.css';

const Banner = (props) => {
    const { header, detail, sno } = props;
    const textRefs = useRef([]);

    useEffect(() => {
      const observerOptions = {
        root: null, // use the viewport as the root.
        threshold: 0.15, // trigger when 15% of element appears.
      };
  
      const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            console.log(entry.isIntersecting);
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target); // stop observing once item gets visible.
          }
        });
      };
  
      const observer = new IntersectionObserver(observerCallback, observerOptions);
  
      textRefs.current.forEach(elRef => {
        if (elRef) observer.observe(elRef);
      });
  
      return () => {
        // cleanup on observer object to disconnect once observed.
        observer.disconnect();
      };
    }, []);

    return (
        sno % 2 !== 0 ? (<div className="banner-container">
            <div className="banner-image">
                <img style={{ objectFit: "contain" }}
                    src={require('../assets/' + props.image)}
                    alt="app_screens" />
            </div>
            <div className="banner-text" ref={el => textRefs.current[sno] = el}>
                <h1>{header}</h1>
                {detail.map((data, idx) => {
                    return (<div key={idx} className='texts'>
                        <p className='bullet'>&#8226;</p><p> {data}</p>
                    </div>)
                })}
            </div>
        </div>) : (<div className="banner-container">
            <div className="banner-text" ref={el => textRefs.current[sno] = el}>
                <h1>{header}</h1>
                {detail.map((data, idx) => {
                    return (<div key={idx} className='texts'>
                        <p className='bullet'>&#8226;</p><p> {data}</p>
                    </div>)
                })}
            </div>
            <div className="banner-image" style={{ display: "flex" }}>
                <img style={{ objectFit: "contain" }}
                    src={require('../assets/' + props.image)}
                    alt="app_screens" />
            </div>
        </div>)
    );
}

export default Banner;