import { colors } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

const AppCarousel = () => {
    const width = window.innerWidth;
    const items = [
        {
            img: require('../assets/online_offline.png'),
            alt: 'online_to_offline_shopping',
            text: "Check if a store is open and find it's location easily."
        },
        {
            img: require('../assets/updates_rewards.png'),
            alt: 'offers_updates',
            text: "Check all latest offer and new arrivals in a store."
        },
        {
            img: require('../assets/delivery_pickup.png'),
            alt: 'delivery_pickup',
            text: 'Place order and pickup from store or get home delivery.'
        },
        {
            img: require('../assets/overall.png'),
            alt: "advertise",
            text: 'Advertise store with StoreDekho and get recommendations and Insights.'
        }
    ];

    return (<Carousel
        indicatorIconButtonProps={{
            style: {
                marginTop: 30
            }
        }}
        activeIndicatorIconButtonProps={{
            style: {
                backgroundColor: colors.amber[900],
                color: colors.amber[900]
            }
        }}
        swipe
    >
        {items.map((item, index) => (
            <div key={index} style={{
                width: width,
                height: width / 2,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                flex: 1
            }}>
                <img src={item.img} style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: "100%" }} alt={item.alt} />
                <p className='para' style={{ fontSize: 16 }}> {item.text}</p>
            </div>
        ))}
    </Carousel>)
};


export default AppCarousel;