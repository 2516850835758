import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import PlayStoreBox from '../components/PlayStoreBox';
import AppCarousel from '../components/AppCarousel';
import LandingHeader from '../components/LandingHeader';
import About from '../components/About';

const RootLayout = () => {

    const banner = [
        {
            header: "Become a seller on StoreDekho!",
            detail: ["Increase your store's visibility.",
                "Get orders from customers & grow your business.",
                "Feature store location on map, open/close timings etc.",
                "Attract more customers with offers, discount and sales.",
                "Get meaningful insights about store that helps analyze your business performance."],
            image: "seller.png"
        },
        {
            header: "Get offers & new arrival updates from stores!",
            detail: ["Get real time updates about a new product arrival & other offers and discounts.",
                "Experience an exclusive marketplace of reliable stores."
            ],
            image: "user_profile.png"

        },
        {
            header: "Get your order delivered or pickup in store!",
            detail: ["Get your order delivered to home.",
                "Order at home and pickup from store to save your precious time.",
                "Enjoy the flexibility that helps customer to save their time mostly in festival and celebration seasons."],
            image: "offers_updates.png"
        },
        {
            header: "Fast & secure payments!",
            detail: ["Be it a refund or an order payment, It is always secure and in a transit.",
                "Get a seamless support for payments and orders on StoreDekho."
            ],
            image: "payments.png"
        }
    ]

    return (
        <>
            <main>
                <LandingHeader />
                {banner.map((data, idx) => {
                    return <div key={idx} style={{ padding: 20, marginTop: idx === 0 ? 20 : 0 }}>
                        <Banner
                            sno={idx}
                            header={data.header}
                            detail={data.detail}
                            image={data.image} />
                    </div>
                })}
                <About />
                {/* Outlet defines where all child routes should be displayed */}
                <Outlet />
                {/* Outlet defines where all child routes should be displayed */}
                <AppCarousel />
                <PlayStoreBox />
            </main>
            <Footer />
        </>
    );
}

export default RootLayout;