import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, Navigate } from 'react-router-dom';
import RootLayout from './pages/Root';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AppRoute from './pages/AppRoute';

function App() {
  const router = createBrowserRouter(createRoutesFromElements(
    <>
      <Route
        path="/"
        element={<RootLayout />}
      >
        <Route path="*" element={<Navigate to='/' replace />} />
      </Route>
      {/* <Route path="mobile/*" element={<AppRoute />} /> */}
      <Route path="termsofuse/" element={<Terms />} />
      <Route path="privacypolicy/" element={<PrivacyPolicy />} />
    </>
  ));
  return (
    <RouterProvider router={router} />
  );
}

export default App;
