import { NavLink, Outlet } from 'react-router-dom';
import { Box, colors } from '@mui/material';

const PlayStoreBox = () => {
    return (<Box
        component={"span"}
        sx={{
            paddingTop: 4,
            marginBottom: 5,
            display: "flex",
            flex: 1,
            textAlign: "center",
            alignItems: "center",
            flexDirection: "column",
            '&:hover': {
                opacity: [0.9, 0.7, 0.7],
            },
        }}
    >

        <NavLink variant="text" to={"https://play.google.com/store/apps/details?id=com.sd.storedekho"}>
            <img src={require('../assets/playstore.png')}
                style={{ objectFit: 'contain', width: 120, height: 120 }} alt={'app'} />
        </NavLink>
    </Box>)
}


export default PlayStoreBox;