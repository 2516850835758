import '../css/components/LandingHeader.css';
import LoginCard from './LoginCard';

const LandingHeader = () => {

    return (<div className="header">
        <div className={"headerContent"}>
            <div className={"toolBar"}>
                <img src={'https://storedekho.in/static/mobileApp/sd-logo-icon.png'} style={{ objectFit: 'contain', width: 300, height: 300 }} alt={'logo'} />
                <img src={'https://storedekho.in/static/mobileApp/sd-top-icon.png'} style={{ objectFit: 'contain', position: "absolute", top: 200, left: 50, width: 250, height: 250 }} alt={'logo'} />
            </div>
            <div className={"toolBar"}>
                <p className={"headerPara"}>
                    <span >We bring a convenient, reliable & informative experience to stores and their customers.</span>
                    <span > Download <strong className={"strongPrime"}>StoreDekho</strong>
                    </span>
                </p>
            </div>
            <div className={"playRedirect"}>
                <a style={{ maxHeight: 100, justifyContent: "center" }} href={"https://play.google.com/store/apps/details?id=com.sd.storedekho"}>
                    <img src={require('../assets/playstore.png')}
                        style={{ objectFit: 'contain', width: 150, height: 150, textAlign: "center" }} alt={'app'} />
                </a>
            </div>
        </div>
        {/* <LoginCard /> */}
    </div>)
};

export default LandingHeader;