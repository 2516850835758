const Terms = () => {
    return (
        <>
            <div>
                <h1 style={{ fontSize: '40px', color: '#DB8F0E', fontWeight: 'bold', textAlign: 'center' }}>
                    Terms of use
                </h1>
                <div className="privacyBox">

                    <div className="termsBox">
                        Welcome to the StoreDekho business. StoreDekho provides you the subject to the following conditions. By visiting the StoreDekho application  (the “MobileApp”), you accept these conditions. Please read them carefully. In addition, when you use any current or future feature related to business or service provided by StoreDekho, you also will be subject to the guidelines, terms and agreements applicable to such service or business.
                        Any activity on the StoreDekho app is not intended for use by children. If you are a minor i.e. under the age of 18 years, you may use the StoreDekho mobile app only with involvement of a parent or guardian. StoreDekho reserve the right to refuse service, terminate accounts, or remove or edit content in their sole discretion.You must not use the StoreDekho mobile app in any way that causes, or is likely to cause, the website or
                        access to it to be interrupted, damaged or impaired in any way.You understand that you, and not StoreDekho, are responsible for all electronic communications and content sent from your computer to us and you must use the mobile app for lawful purposes only and only in accordance with the applicable law.
                    </div>
                    <p className="para">
                        You must not use the Site for any of the following:for fraudulent purposes,
                        or in connection with a criminal offence or other unlawful activity;to send, use or reuse any material that is illegal,
                        offensive, (including but not limited to material that is sexually explicit or which promotes racism, bigotry, hatred or physical harm), abusive, harassing, misleading, indecent, defamatory, disparaging, obscene or menacing; or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any “spam”;to cause annoyance, inconvenience or anxiety.
                    </p>
                    <p className="para">
                        This document is an electronic record and published inaccordance with the provisions of the Information Technology Act 2000  and the rules thereunder and generated by acomputer system and does not require any physical or digital signatures.
                    </p>
                    <p className="para">
                        A. This website "www.storedekho.in" (Website) and mobile application "StoreDekho" (App) (Website and App collectively referred to as the “Platform”)is operated and managed by the Company. The term Company shall collectively and individually refer to each company which operates and manages the Platform with respect to the respective cities.
                    </p>
                    <p className="para">
                        B.  These terms of use (“Terms”) govern Your use of the Platform, and for the purpose of these Terms the Company may wherever context so require, be also referred to as, “StoreDekho”, "We", "Us", or "Our" and the terms “You”, “Your” or “User” refer to user of the Platform. We value the trust You place in Us. That’s why, We maintain reasonable security standards for securing the transactions and Your information.
                    </p>
                    <p className="para">
                        C.   Please read these Terms carefully before You use the services. If You do not agree to these Terms, You may not use the Services (defined hereinafter) on the Platform, and We request You to uninstall the App. By even merely using/accessing the Platform, You shall be contracting with StoreDekho and You signify Your acceptance to these Terms and other StoreDekho policies (including but not limited to the cancellation & refund policy of the Company and published privacy policy (‘Privacy Policy’)) as posted on the Platform and amended from time to time, which takes effect on the date on which You use the Platform, and thereby create a legally binding arrangement to abide by the same. Further, by furnishing Your personal information, You consent to StoreDekho accessing Your personal information for the purpose of rendering the Services (as defined below), You also agree that You are interested in availing the Services through the Platform in accordance with these Terms.
                    </p>
                    <p className="para">
                        D.   StoreDekho retains an unconditional right to modify or amend these Terms. You can determine when these Terms were last modified by referring to the “Last Updated” legend above. You can access the latest version of these Terms at any given time on the Platform. You should regularly review the Terms on the Platform. Your continued use of and access to the Platform shall be Your consent to such changes. In the event the modified Terms are not acceptable to You, You should discontinue accessing the Platform.
                    </p>
                    <p className="para">
                        E.   Any refunds will only be processed within 7 to 10 business days if applicable. It could be pre or post 7 business days depending on holidays or any other technicalities.
                        Return should only be placed between business hours and should be picked up within specified timeline and attempts.
                    </p>
                    <div className="termsBox">
                        1.  Services
                    </div>
                    <div className="termsBox">
                        1.1.        You acknowledge that the Platform allows You to avail the following services including:
                    </div>
                    <div className="termsBox">
                        1.1.1.        a personal, limited, non-exclusive, non-transferable, and revocable privilege to access and use the Platform for the purposes of purchasing select products including consumer goods such as grocery, pharmaceutical products, alcohol beverages, tobacco products (collectively, “Product(s)”) from the Company, sold on a business to consumer (B2C) basis for personal consumption and not for resale;
                    </div>
                    <div className="termsBox">
                        1.1.2.        accessing services provided by the Company, through the Platform, including but not limited to last mile delivery of Products; and
                    </div>
                    <div className="termsBox">
                        1.1.3.        promptly and efficiently responding to Your queries or issues relating to Your Account and the services availed by You, using the Platform.
                    </div>
                    <div className="termsBox">
                        1.2.        The aforementioned services shall be collectively referred to as the “Services”. The said revocable privilege to access does not include/permit resale or commercial use of the Platform or its Content (as defined below), in any manner. The Company may, at its discretion, add, modify, or remove any of the Stores, Products and/or Services listed above from time to time without notice. The Company may also make applicable, at its discretion, additional terms and conditions specific to any category or section of Products in addition to these Terms and Your purchase of any of such category or section of Products shall also be governed by such additional terms and conditions. The Company reserves the right to provide the Services in any area or city in India, as may be determined by the Company in its sole discretion. Before applying or registering for availing any of the Services, we request You to please check if the Services are available in Your area or city. The stores provide last mile delivery of Products at their own discretion who will be providing the delivery services for a service fee.
                    </div>
                    <div className="termsBox">
                        1.3.        The Company does not: (i) adopt any ‘unfair trade practices’ either on the Platform or otherwise with respect to the Products and Services; (ii) discriminate between Users of the same class or make any arbitrary classification of the Users; and (iii) discriminate between the third-party delivery service providers.
                    </div>
                    <div className="termsBox">
                        2.  Eligibility to Use
                    </div>
                    <div className="termsBox">
                        2.1.        The Services are not available to minors i.e., persons under the age of 18 (eighteen) years, undischarged insolvent, or to any Users who are not competent to enter into a contract under the Indian Contract Act,1872, unless otherwise provided hereunder. You hereby represent that You are:(i) of legal age to form a binding contract, (ii) not a person barred from receiving the Services from the Platform under the applicable laws; and (iii)competent to enter into a binding contract. If You are under the age of 18, You shall use and access the Services only with the express consent of a parent or guardian and under their supervision.
                    </div>
                    <div className="termsBox">
                        2.2.       You will not discriminate against  delivery service providers based on race, religion, caste, creed, national origin, disability, sexual orientation, sex, marital status, gender identity, age or any other metric which is deemed to be unlawful under applicable laws. Any credible proof of such discrimination, including any refusal to receive goods or services based on the above metrics, whether alone or in conjunction with any other metric, shall render you ineligible, leading to suspension of Services. You will not have any claim towards, and we will not have any liability towards any suspension which is undertaken as a result of the aforementioned event.
                    </div>
                    <div className="termsBox">
                        3.  User Account, Password, and Security
                    </div>
                    <div className="termsBox">
                        3.1.        In order to access the Services on the Platform, You will have to register and create an account on the Platform by providing details as may be required in the Privacy Policy (“Account”).
                    </div>
                    <div className="termsBox">
                        3.2.        You are solely responsible for the information You provide to Us. You shall ensure and confirm that the Account information and all information provided byYou is complete, accurate and up-to-date. If there is any change in the Account information, or if any information is found to be incomplete or incorrect, You shall promptly update Your Account information on the Platform or request StoreDekho for information revision or update. If the information provided by You is untrue, inaccurate, unauthorised, not current, or incomplete (or becomes untrue, inaccurate, not current, or incomplete), or StoreDekho reserves the right to refuse any and all the Services, if StoreDekho has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete.. StoreDekho reserves the express right to refuse access to theServices at any time without notice for Your failure to abide by the Terms asset forth herein or to comply with applicable laws including with respect to accessing  alcoholic beverages and/or tobacco products through the Platform.
                    </div>
                    <div className="termsBox">
                        3.3.        You will be responsible for the confidentiality of the Account information provided and fully responsible for all activities that take place on Your Account. You agree to immediately notify StoreDekho of any unauthorised access of Your Account. You may be held liable to pay damages for losses caused due to unauthorised use of Your Account as a result of Your failure to keepYour Account information secure and confidential.
                    </div>
                    <div className="termsBox">
                        4.  Payment related Information
                    </div>
                    <div className="termsBox">
                        4.1.        The information relating to the accepted payment methods on the Platform shall be displayed during the purchasing process.
                    </div>
                    <div className="termsBox">
                        4.2.        To the extent permitted by applicable law and subject to the Privacy Policy, You acknowledge and agree that the Company may use certain third-party vendors and service providers, including payment gateways, to process payments and manage payment card information. The Company can ensure such third-party vendors and service providers possess necessary licenses from the appropriate authority.
                    </div>
                    <div className="termsBox">
                        4.3.        In order to avail the Services, You undertake to use and provide valid bank details or other details required for facilitating payment towards the Services (“Payment Details”). By providing the Payment Details, You represent, warrant, and covenant that: (a) You are legally authorized to provide such Payment Details; (b) You are legally authorized to perform payments using such Payment Details; and(c) such action does not violate the terms and conditions applicable to Your use of such Payment Details or applicable law.You may add, delete, and edit the Payment Details You have provided from time to time through the Platform.
                    </div>
                    <div className="termsBox">
                        4.4.         Except to the extent otherwise required by applicable law, the Company is not liable for any payments authorized through the Platform using Your Payment Details. Particularly, the Company is not liable for any payments that do not complete because: (a) Your bank account or any other payment did not contain sufficient funds to complete the transaction; (b) You have not provided the Company with correct Payment Details; (c) Your payment card has expired; or (d) circumstances beyond the Company’s reasonable control (such as, but not limited to, power outages, interruptions of cellular service, or any other interferences from an outside force) which prevent the execution of the transaction.
                    </div>
                    <div className="termsBox">
                        4.5.         The Company shall not be responsible for any unauthorised transactions conducted on the Platform using Your Payment Details. The Company shall not be obligated to refund any money to You in such instances.
                    </div>
                    <div className="termsBox">
                        4.6.          The payment facility provided by Company is neither a banking nor a financial service but is merely a facility providing an electronic, automated online electronic payment system, and receiving payment on delivery for the transactions on the Platform using the existing authorized banking infrastructure and card payment gateway networks, as may be applicable. For some payment methods, Your issuer may charge You certain fees, such as transaction fees or other fees, relating to the processing of Your transaction
                    </div>
                    <div className="termsBox">
                        5.  Prices of Products
                    </div>
                    <div className="termsBox">
                        5.1. The prices of each of the Products may vary due to various factors and You may check the price on the Platform before placing an order. By placing an order You agree to pay  the prices mentioned therein. All the Products listed on the Platform will be sold at Indian Rupees either at Maximum Retail Price (MRP)(inclusive of all taxes) or at a discounted price unless otherwise specified. The prices of the Products may be modified from time to time.
                        . There can be an unintended error with respect to the Prices and other information of the Products. You may bring it to Our notice of such errors and we shall effect necessary corrections.
                    </div>
                    <div className="termsBox">
                        5.2.        The Users will be informed about all the charges, fees, and costs (including, delivery fee) that may be levied on the purchase of the Products on the Platform at the checkout page during a transaction. The Company does not manipulate the price of any Products and/or Services offered on the Platform
                    </div>
                    <div className="termsBox">
                        6.1.        The store on company’s platform shall provide delivery of the Products during such time period as communicated to You through the Platform.
                        the Company does not guarantee the delivery within the said time
                    </div>
                    <div className="termsBox">
                        6.2.         The delivery of the Products will be made to the delivery address specified by You while placing the order for the Products on the Platform. You are solely responsible for providing a complete and correct delivery address and, if applicable, further instructions for delivery. The Company shall not be responsible for any delay in delivering the order placed by You on the Platform caused due to the incorrect or incomplete address provided by You.
                        If You request to leave a delivery unattended at Your address, You expressly disclaim the Company from all the liabilities which may arise by virtue of the Products being left unattended for a period of time. This includes but is not limited to theft, tampering, contamination, and the result of any change in temperature of the items which need to be kept chilled or frozen.
                    </div>
                    <div className="termsBox">
                        6.4.         In the event You opt to make payment for the Products by ‘Cash on Delivery (CoD)’ mode, the store shall have the right to refuse delivery of the ordered Products to You if You fail to make the complete payment and the Company shall treat such order as cancelled order and shall shall not be liable for any losses or damage that may arise on account of such non – delivery to You. The Company shall reserve the right to charge You, the delivery fee for such cancelled order and recover the same, at its sole discretion towards the cost incurred on such delivery attempt.
                    </div>
                    <div className="termsBox">
                        6.5.         Alcoholic Beverage and Tobacco Products:
                    </div>
                    <div className="termsBox">
                        6.5.1. The delivery of alcoholic beverage and tobacco products can only be made to You if You comply with the eligibility criteria prescribed under applicable law, which may vary from State to State. The company may request You to provide Your identification and age proof documents to evidence compliance with the aforesaid, including permanent account number (PAN) card or other documents or such other information, as maybe required by law at the time of ordering and delivery of alcoholic beverage and tobacco products. Notwithstanding anything contained in these Terms, the store shall deliver the ordered alcoholic beverage and tobacco products, only to the person from whose account the order has been placed  subject to verification at the time of delivery. The store may refuse the delivery of the ordered products in case of non – compliance with this requirement. We do not sell loose cigarettes.
                    </div>
                    <div className="termsBox">
                        6.5.2. By accessing the alcoholic beverage and tobacco products category on the Platform, You represent that You (i) comply with all eligibility criteria under applicable law including legal drinking age in Your state of domicile and/ or where You are accessing the Platform and/or placing the order, (ii) have not been previously suspended or prohibited from accessing or otherwise availing the Services of the Platform; (iii)possess necessary permits, if applicable; and (iv) order for Your personal consumption and not for resale.
                    </div>
                    <div className="termsBox">
                        6.5.3.  Important User declaration:
                    </div>
                    <div className="termsBox">
                        You agree and undertake that You will not provide the address of any public place, including but not limited to, educational institution, hospital, religious places as Your delivery address for the order relating to alcoholic beverage and tobacco products.Further, You agree and undertake, when You buy alcohol, that You are not;
                    </div>
                    <div className="termsBox">
                        {`a.    in drunken condition;`}
                    </div>
                    <div className="termsBox">
                        b.    taking part in any unlawful activity;
                    </div>
                    <div className="termsBox">
                        c.    (uniformed official) on duty or in uniform; and
                    </div>
                    <div className="termsBox">
                        d.    below the legal drinking age.
                    </div>
                    <div className="termsBox">
                        6.5.4.  The Company and/or the store reserves the right to refuse delivery of such order to You in case of any non –compliance by You of the above conditions in the bona fide opinion of the Company or the third party delivery service providers.
                    </div>
                    <div className="termsBox">
                        7.  Returns, Cancellations, and Refunds,
                    </div>
                    <div className="termsBox">
                        7.1 Returns
                        7.1.1       You may return the Product in an order, subject to the following, conditions:
                    </div>
                    <div className="termsBox">
                        7.1.1.1         wrong item being delivered other than what You had ordered in an order or
                    </div>
                    <div className="termsBox">
                        7.1.1.2        Items substantially damaged or deteriorated in quality at the time of delivery. You agree that You shall give Us all the requisite proofs including but not limited to images ofProducts having issues.
                    </div>
                    <div className="termsBox">
                        7.1.2       You shall check the Products upon delivery and initiate exchange or return with the requisite proofs on the Platform. You may exchange or return the product by contacting Our customer care also. IT IS HEREBY CLARIFIED THAT NO EXCHANGE OR RETURNS SHALL BE ACCEPTED IF THE PRODUCT PACKAGING IS OPENED OR PRODUCT ISUSED/CONSUMED EITHER IN PART OR OTHERWISE.
                    </div>
                    <div className="termsBox">
                        7.2  Cancellations:You may cancel an order without charge at any time beĵfore the store accepts the order. You cannot cancel the order post acceptance of order by the store.The store may cancel an order (in full or partially) for the reasons including shortage or unavailability of certain Products or force majeure events. In case the store cancels any order, You will not be charged for such cancellations, and the Company will refund You for any payment already made.
                    </div>
                    <div className="termsBox">
                        7.3  Refunds:. Please be informed that when You opt to cancel or return the Products, upon our verification of the Products and the documents relating thereto, the refund amount for such Products which are eligible for return as per these Terms, will be processed within a period of seven (7) business days from the date of us verifying and confirming the refund request. Your refund will be processed only when the conditions as may be stipulated by Us are fulfilled. The refund amount will be credited to the source account as may be chosen by You. You acknowledge that after initiation of refund, it may take additional time for Your refund to reflect in Your account which is subject to Your financial institution or payment gateway service provider terms and conditions
                    </div>
                    <div className="termsBox">
                        7.4  The terms for acceptance of returns, cancellation and refunds shall be subject to reasonable additional conditions and shall be communicated to the User, from time to time, through the push notifications on the Platform, or any other mode of communication as specified in these Terms or as determined by the Company.
                    </div>
                    <div className="termsBox">
                        8.  User care:
                        8.1 The warranty and guarantee, if any, applicable to the Products shall be that of the manufacturer and You may directly reach out to the store or manufacturer  through the customer care details made available on the Product packaging. The Company is merely an aggregator and disclaims any liability with respect to the manufacturing defects, quality, taste, performance of the Products sold.
                    </div>
                    <div className="termsBox">
                        8.2 You agree and understand that the Product images are representation of the Product and not actual image of the Product sold to You and You shall read the physical product label for the calorific and nutrition value, using instructions, batch, manufacture date, content, weight, manufacturer and the customer care details, as may be relevant, before consumption of the Products. WHILE EVERY REASONABLE EFFORT IS MADE TO MAINTAIN ACCURACY OF INFORMATION ON THE PLATFORM, ACTUAL PRODUCT PACKAGING, MAY CONTAIN MORE AND/OR DIFFERENT INFORMATION THAN WHAT IS SHOWN ON THE PLATFORM. IT IS RECOMMENDED TO REFER THE INFORMATION PRESENTED ON THE ACTUAL PRODUCT PACKAGING
                    </div>
                    <div className="termsBox">
                        8.3 Company reserves its right to refuse to process transactions by Users with a prior history of questionable transactions including without limitation breach of any agreements by User with Company or breach/violation of any law or any charges imposed by bank or breach of any policy without giving any reasons. Company may do such checks as it deem fit before approving the User's order for security or other reasons at the discretion of Company. As a result of such checks, if the Company is not satisfied with the credibility of the User or genuineness of the transaction, it will have the right to reject such an order. Company may delay the dispatch or cancel any transaction at its sole discretion, if it is suspicious of any User's authenticity or activity or if the User is conducting high transaction volumes, to ensure safety of the transaction.
                    </div>
                    <div className="termsBox">
                        8.4  Beware of fraud:
                    </div>
                    <div className="termsBox">
                        8.4.1.  Please do not share Your debit/credit card number, CVV number,OTP, UPI/ATM pin and other sensitive information with anyone claiming to be aCompany representative. Company or its authorised representatives will NEVER ask You to share the aforesaid details. Beware of fraudsters and please report incidents immediately to Your bank, the nearest police station and at https://cybercrime.gov.in/.
                    </div>
                    <div className="termsBox">
                        8.4.3.   Please exercise caution to verify the portals/website links claiming to be StoreDekho or a lookalike or a payment link shared over social media or a social messaging apps claiming to be StoreDekho discounts or offers and proactive calls from unauthorised numbers requesting for personal/financial information.
                    </div>
                    <div className="termsBox">
                        9.  Use of Platform
                    </div>
                    <div className="termsBox">
                        9.1. Subject to compliance with the Terms, the Company hereby grants You a personal, non-exclusive, non-transferable, limited, revocable privilege to access and use the Platform. You agree to use the Platform only:
                        <br />(a) for purposes that are permitted by the Terms;
                        <br />(b) in accordance with any applicable law, regulation or generally accepted practices or guidelines; and
                        <br />(c) for availing the Services through the Platform. You agree not to engage in activities that may adversely affect the use of the Platform by the Company and/or other Users.
                    </div>
                    <div className="termsBox">
                        9.2. You agree that the Platform or any portion of the Platform shall not be reproduced, duplicated, copied, sold, resold or otherwise exploited for commercial purposes.
                    </div>
                    <div className="termsBox">
                        9.3. You agree to not frame or utilise the framing techniques to enclose any trademark, logo or any other proprietorship information of the Platform.
                    </div>
                    <div className="termsBox">
                        9.4. You agree not to access (or attempt to access) the Platform by any means other than through the interface that is provided by the Company. You shall not use any deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform, materials or any Content, to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Platform.
                    </div>
                    <div className="termsBox">
                        9.5. Further, You undertake not to host, display, upload, modify, publish, transmit, store, update or share any information that:
                        <br />9.5.1. belongs to another person and to which the User does not have any right;
                        <br />9.5.2. is defamatory, obscene, pornographic, paedophilic, invasive of another's privacy, including bodily privacy, insulting, or harassing on the basis of gender, libellous, racially, or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force;
                        <br />9.5.3. is harmful to child;
                        <br />9.5.4. infringes any patent, trademark, copyright, or other proprietary rights;
                        <br />9.5.5. violates any law for the time being in force;
                        <br />9.5.6. impersonates another person;
                        <br />9.5.7. threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation;
                        <br />9.5.8. contains software virus or any other computer code, file or program designed to interrupt, destroy, or limit the functionality of any computer resource;
                        <br />9.5.9. is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity, or agency for financial gain or to cause any injury to any person;
                        <br />9.5.10. disrupt or interfere with the security of, or otherwise cause harm to, the Platform, systems resources, accounts, passwords, servers, or networks connected to or accessible through the Platform or any affiliated or linked sites;
                        <br />9.5.11. violate the Terms contained herein or elsewhere; and
                        <br />9.5.12. reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Platform.
                    </div>
                    <div className="termsBox">
                        9.6. You shall solely be responsible for maintaining the necessary computer equipment, gadgets and internet connections that may be required to access, use, and transact on the Platform.
                    </div>
                    <div className="termsBox">
                        9.7. You understand and acknowledge that by using the Platform or any of the Services, You may encounter Content that may be deemed by some Users to be offensive, indecent, or objectionable, which Content may or may not be identified as such. You agree to use the Platform and any Services at Your sole risk and that to the fullest extent permitted under applicable law. The Company shall have no liability to You for Content that may be deemed offensive, indecent, or objectionable to You.
                    </div>
                    <div className="termsBox">
                        10.  Intellectual Property Rights
                    </div>
                    <div className="termsBox">
                        10.1      The Platform and the processes, and their selection and arrangement, including but not limited to, all text, videos, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code (and any combinations thereof) (collectively, the “Content”) on the Platform is licensed to the Company and the design, structure, selection, coordination, expression, look and feel and arrangement of such Content is protected by copyright, patent and trademark laws, and various other intellectual property rights.
                        <br />10.2      The trademarks, logos and service marks displayed on the Platform(“Marks”) are the licensed property of the Company or owned by third parties. You are not permitted to use the Marks without the prior consent of the Company or the relevant third party (which is the owner of the Marks) respectively. Access or use of the Platform does not authorise any party to use trademarks, logo, or any other mark in any manner.
                        <br />10.3      The Company disclaims to hold any right, title, or interest in and to the intellectual property rights arising out of or associated with the Products.
                        <br />10.4      References on the Platform of any name, mark, services or products of third parties has been provided after the express consent of such third party for Your convenience, and in no way constitutes an endorsement, sponsorship or recommendation, whether express or implied.
                    </div>
                    <div className="termsBox">
                        11.  Disclaimer of Warranties & Liability
                        <br />11.1      You expressly understand and agree that, to the maximum extent permitted by applicable law:
                        <br />11.1.1        The Platform and other Content are provided by the Company on an “as is” basis without warranty of any kind, express, implied, statutory, or otherwise, including the implied warranties of title, non-infringement, merchantability, or fitness for a particular purpose. Without limiting the foregoing, the Company makes no warranty that the Platform or Services will meet Your requirements, or Your use of the Platform will be uninterrupted, timely, secure, or error-free. No advice or information, whether oral or written, obtained by You from the Company shall create any warranty not expressly stated in the Terms.
                        <br />11.1.2         Company will not be liable for any loss that You may incur as a consequence of unauthorized use of Your Account or Account information in connection with the Platform either with or without Your knowledge.
                        <br />11.1.3         Company has endeavoured to ensure that all the information on the Platform is correct, but the Company neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information regarding the Services or otherwise. The Company shall not be responsible for the delay or inability to use the Platform or related functionalities, the provision of or failure to provide functionalities, or for any information, software, functionalities, and related graphics obtained through the Platform, or otherwise arising out of the use of the Platform, whether based on contract, tort, negligence, strict liability or otherwise.Further, the Company shall not be held responsible for non-availability of thePlatform during periodic maintenance operations or any unplanned suspension of access to the Platform that may occur due to technical reasons or for any reason beyond the Company’s reasonable control.
                        <br />11.2      While the Company has made best efforts to display the colours of the Product image on the Platform as accurately as possible. However, the actual colours of the Products You see will depend on Your monitor or device, and the Company does not provide any guarantee in respect of such display and will not be responsible or liable for the same.
                        <br />11.3      The Company makes no representation that the Content on the Platform is appropriate to be used or accessed outside the Republic of India.Any Users who use or access the Platform from outside the Republic of India, do so at their own risk and are responsible for compliance with the laws of such jurisdiction.
                        <br />11.4      The Terms do not constitute, nor may the Terms be used for or in connection with any promotional activities or solicitation by anyone in any jurisdiction in which such promotional activities or solicitation are not authorized or to any person to whom it is unlawful to promote or solicit.
                    </div>
                    <div className="termsBox">
                        12.  Indemnification and Limitation of Liability
                        <br />12.1       You agree to indemnify, defend and hold harmless StoreDekho and its affiliates including but not limited to its (and its affiliates’) officers, directors, consultants, agents, representatives and employees; and stores (“Indemnitees”) from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including reasonable legal fees) asserted against or incurred by the Indemnitees that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by You pursuant to these Terms and/or the Privacy Policy. Further,You agree to hold the Indemnitees harmless against any claims made by any third party due to, or arising out of, or in connection with, Your use of thePlatform, any misrepresentation with respect to the data or information provided by You, Your violation of the Terms and/or the Privacy Policy, Your violation of applicable laws, or Your violation of any rights of another, including any intellectual property rights.
                        <br />12.2       In no event shall the Company and its directors, officers, partners, consultants, agents, and employees and its third party partners, be liable to You or any third party for any special, incidental, indirect, consequential, or punitive damages whatsoever, arising out of or in connection with Your use of or access to the Platform orContent on the Platform.
                        <br />12.3        The limitations and exclusions in this Section apply to the maximum extent permitted by applicable laws.
                    </div>
                    <div className="termsBox">
                        13.  Violation of these Terms
                        <br />13.1         You agree that any violation by You of these Terms will likely cause irreparable harm to the Company, for which monetary damages would be inadequate, and You consent to the Company obtaining any injunctive or equitable relief that they deem necessary or appropriate in such circumstances.These remedies are in addition to any other remedies that the Company may have at law or in equity.
                    </div>
                    <div className="termsBox">
                        14.  Suspension and Termination
                        <br />14.1         The Terms will continue to apply until terminated by either You or the Company as set forth below. If You object to the Terms or are dissatisfied with the Platform, You may (i) close Your Account on the Platform; and/or (ii) stop accessing the Platform.
                        <br />14.2         The Company may disable Your access or block Your future access to thePlatform or suspend or terminate Your Account if it believes, in its sole and absolute discretion, that You have violated any term of these Terms or the PrivacyPolicy or in any way otherwise acted unethically. Notwithstanding anything in this Clause, all terms which by their nature are intended to survive such termination, will survive indefinitely unless and until the Company chooses to terminate them.
                        <br />14.3         Any such termination under clause 14.1 and 14.2 above shall not cancel Your obligation to pay for a Product purchased on the Platform, or any other obligation which has accrued, or is unfulfilled and relates to the period, prior to termination.
                        <br />14.4         You shall be liable to pay any fees or charges, if applicable in respect of the Services until the date of termination by either party whatsoever.
                    </div>
                    <div className="termsBox">
                        15. Governing Law and Jurisdiction
                        <br />15.1          These Terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of courts at Mumbai, India.
                    </div>
                    <div className="termsBox">
                        16.  Grievance Redressal Mechanism
                        <br />16.1         Grievance Handling:
                        <br />16.1.1        For any order related issue, You may first reach out to Us via on call support on the App for real time basis resolution.
                        <br />16.1.2       You may also write to Us at support@StoreDekhonow.com and We will strive to resolve Your order related grievance within the timelines prescribed under applicable laws.
                        <br />16.1.3       If You still have any grievances, or complaints or concerns with respect to the Platform or order or are not satisfied with the resolution, the Content, or the Services, You can contact the designated Grievance cum Nodal Officer of the Company, can be accessed here.Details of the Grievance cum Nodal Officer is provided in compliance of (1) Information Technology Act, 2000 and rules made there under,  and (2) Consumer Protection (E-Commerce) Rules 2020, as amended time to time.
                        <br />16.2      The Grievance Officer of the Company shall endeavour to acknowledge the User grievances, or complaints or concerns with respect to the Platform, the Content, or the Services, within 48 hours of receipt of the same and shall endeavour to redresses the same at the earliest and in no event later than 30 (thirty) days of receipt of such request. By lodging a complaint or grievance, You agree to provide complete support to the Grievance Officer and such reasonable information as may be sought by them from You.
                    </div>
                    <div className="termsBox">
                        17.  Communications
                        <br />17.1         You hereby expressly agree to receive communications by way of SMS, telephone or VOIP calls, messaging app like WhatsApp on the registered mobile phone number /or electronic communications like e-mails from the Company and other third parties duly authorised by the Company. You hereby expressly consent to the monitoring and recording, by the Company of any and all communications between You and the Company or its agents, employees, consultants, contractors, or representatives of the Company or of their authorised partners, and such monitoring or recording waives any further notice or consent requirement under the applicable laws.
                        <br />17.2          You can unsubscribe or opt-out from receiving communications from the Company. In which case, the Company will only send communications solely required for the purposes of availing the Services by You.
                    </div>
                    <div className="termsBox">
                        18.  General Provisions
                        <br />18.1           Notice: All notices from the Company will be served by email to Your registered email address or by messaging app on the registered mobile phone number or by general notification on the Platform.
                        <br />18.2          Assignment: You cannot assign or otherwise transfer any rights granted hereunder to any third party. The Company’s rights under the Terms are freely transferable by the Company to its successor or any third party without the requirement of seeking Your consent.
                        <br />18.3           Severability: If, for any reason any provision of the Terms, or any portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms shall continue in full force and effect.
                        <br />18.4           Force Majeure: The Company and/or the Platform shall not be liable to You for its failure to perform or for delay in providing You access to Your Account or to the Platform or any Services thereof, to the extent such failure or delay results from causes beyond its reasonable control, including, without limitation, acts of God, fires, explosions, wars or other hostilities, insurrections, revolutions, strikes, labour unrest, earthquakes, floods, pandemic, epidemics or regulatory or quarantine restrictions, unforeseeable governmental restrictions or controls or a failure by a third party hosting provider or internet service provider or on account of any change or defect in the software and/or hardware of Your computer system.
                    </div>
                    <div className="termsBox">
                        19.  IP Infringement
                        <br />19.1              If You believe the Platform violates Your intellectual property,You must promptly notify theCompany in writing at legal@StoreDekhonow.com. These notifications should only be submitted by the owner of the intellectual property, or an agent authorized to act on his/her behalf.However, any false claim by You may result in the termination of Your access to the Platform.
                        <br />19.2             You are required to provide the following details in Your notice:
                        <br />19.2.1        the intellectual property that You believe is being infringed with proof of Ownership;
                        <br />19.2.2       the item that You think is infringing and include sufficient information about where the material is located on the Platform;
                        <br />19.2.3       a statement that You believe in good faith that the item You have identified as infringing is not authorized by the intellectual property owner, its agent, or the law to be used in connection with the Platform;
                        <br />19.2.4        Your contact details, such as Your address, telephone number, and/or email;
                        <br />19.2.5        a statement, under penalty of perjury, that the information You provided in Your notice is accurate, and that You are the intellectual property owner, or an agent authorized to act on behalf of the intellectual property owner whose intellectual property is being infringed; and
                        <br />19.2.6        Your physical or electronic signature.
                        <br />19.2.7        Please note: Company shall not consider an incomplete request.
                    </div>
                    <div className="termsBox">
                        20.  Advertisements
                        <br />20.1.        As part of the Services provided by Us; We may facilitate and allow third party advertisers (“ThirdParty Advertisers”) to place advertisements on the Platform. You understand that any content put out by Third Party Advertisers is not edited, reviewed or otherwise endorsed by theCompany and we disclaim to the fullest extent permitted by law any liability for the content published by the Third Party Advertisers.
                        <br />20.2.       To the extent You are a Third Party Advertiser You understand that in addition to these Terms You will also be required to agree to the Company’s policies and other contractual agreements that You will need to execute for placing Your advertisement. As a general principle the content in the advertisements should not be misleading or in violation of applicable law or guidelines issued by the Advertising Standards Council of India or any other self-regulating body. You also acknowledge that we have the sole right at our discretion to remove anyThird Party Advertisement or require You to prove factual substantiation if we are of the view that it is in violation of applicable law or any self-regulating industry body guidelines or is otherwise misleading.
                        <br />20.3.        If You are of the view that the content of a third party advertiser is inappropriate or in violation of applicable law, please write to us at the email address provided above.
                    </div>
                </div>
            </div>
        </>
    );
}

export default Terms;