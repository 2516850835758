import React from 'react';
import '../css/components/Footer.css';
import { NavLink } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ApprovalIcon from '@mui/icons-material/Approval';

const Footer = (props) => {

    return (
        <div className="container">
            <div className='cont'>
                <div className='_details'>
                    <p className="info"><LocationOnIcon color='#DB8F0E' fontSize={'small'} />&nbsp; Registered Address :
                    </p>
                    <p className="info">
                        A-17 2nd Floor, Sanjay Nagar Old raj nagar
                    </p>
                    <p className="info">
                        Ghaziabad Uttar Pradesh, 201002
                    </p>
                </div>
                <div className='_details'>
                    <p className="info"><ContactMailIcon color='#DB8F0E' fontSize={'small'} />&nbsp; Grievances & Support</p>
                    <p className="info">Email ID: info@storedekho.in</p>
                </div>
                <div className='_details'>
                    <p className="info"><ApprovalIcon color='#DB8F0E' fontSize={'small'} />&nbsp; Registered Company Name: </p>
                    <p className="info">ASKNJ 7T9 Communication Pvt. Ltd.</p>
                    <p className="info">CIN - U62099UP2023PTC186947</p>
                </div>
            </div>
            <div className='cont'>
                <p className="footer-text">© 2024 StoreDekho, All rights reserved.</p>
                <div className='extrmFooter'>
                    <nav>
                        <ul className="nav-list">
                            <li>
                                <NavLink
                                    end
                                    to="/termsofuse"
                                    className={"nav-link"}
                                >
                                    Terms of use
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    end
                                    to="/privacypolicy"
                                    className={"nav-link"}
                                >
                                    Privacy policy
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Footer;