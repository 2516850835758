import { Typography, Card, CardContent, Grid } from '@mui/material';
import '../css/pages/About.css';
import EmbossedCard from './EmbossedCard';

const About = () => {
    const data = [
        {
            title: "About Us",
            desc: "We serve as aggregators, bridging the gap between brick and mortar store vendors and their customers. We offer a platform where store's customers can put orders online and get home delivery or pick order from store, saving their time and effort to explore all stores in the market."
        },
        {
            title: "The Purpose",
            desc: "Our purpose is to serve as a bridge, connecting store vendors with their valued customers. We provide a platform for businesses to share information about their stores, including exclusive offers, discounts, and other important updates. By doing so, we aim to expand the presence of these stores, stimulate their growth, and ensure that every business has an equal and fair opportunity in the market. Hence, Promote Free and Fair Chance Among Market."
        },
        {
            title: "Our Story",
            desc: "StoreDekho was born out of a shared passion for helping businesses grow and succeed. Our journey began when a group of like-minded individuals came together, each with their own unique experiences in the world of commerce. We realized that many vendors, especially small and local businesses, face numerous challenges when trying to compete in today's fast-paced market. The idea of creating a platform where businesses could connect with their customers and share their offerings took shape. We believed that by providing this bridge, we could not only give vendors a chance to shine but also ensure that customers could stay updated and receive all offer updates about the store. Over time, our mission and vision crystallized into what they are today, and we set out to build a platform that would promote fairness, empower vendors, and inform customers and get customers engaged with stores. At StoreDekho, we are dedicated to making a positive impact on businesses and customers alike. Join us on our journey towards a more equitable, informed, and a prosperous marketplace."
        }];
    return (
        <div className='about-container'>
            {data.map((obj, idx) => {
                return (
                    <EmbossedCard key={idx} header={obj.title} description={obj.desc} />
                )
            })}
        </div>
    );
}

export default About;